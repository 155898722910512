.app-loader {
  border: 0.2em solid #f3f3f3;
  border-top: 0.2em solid;
  border-radius: 50%;
  width: 0.8em;
  height: 0.8em;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
