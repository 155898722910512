.contact {
  background-color: rgba(237, 236, 193, 0.18);
  color: #353D2F;
  direction: ltr;
  &.success {
    background-color: #353D2F;
  
    .success-title {
      font-size: 1.0625em;
      color: #EDECC1;
      text-align: center;
      margin-top: 3.58em;
    }
  
    .success-title-smile {
      direction: ltr;
  
      span {
        font-size: 1.5em;
      }
  
    }
  
    .success-btn {
      cursor: pointer;
      margin-top: calc(100vh * 30 / 100);
      font-size: 0.75em;
      background-color: #EDECC1;
      border: 1px solid #353D2F;
      border-radius: 3.33em;
      color: inherit;
      height: 2.166em;
      width: 10.916em;
      display: block;
      font-family: inherit;
      margin-inline: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.contact-form {
  margin-inline: auto;
  margin-block: 3.8125em;
  margin-left: auto;
  margin-right: auto;

  .form-title {
    font-size: 1.062em;
    text-align: center;
    margin-bottom: 1em;
  }

  .field-wrapper {
    margin-bottom: 1.25em;

    input,
    select,
    textarea {
      height: 2.5em;
      width: 22.25em;
      padding-inline-start: 1em;
      border: 0.03125em solid #353D2F;
      font-size: .875em;
      font-family: inherit;
    }
    textarea {
      height: auto;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
    }
    &.field-terms {
      label {
        align-items: center;
        display: flex;
        font-size: .875em;
      }
      input {
        appearance: none;
        border: 1px solid #353d2f;
        font-size: inherit;
        height: 1.08em;
        margin-inline-end: 0.75em;
        outline: none;
        width: 1.08em;
        margin-inline-start: 0;
        &:checked {
          background-color: #353d2f;
          border: 1px solid #353d2f;
      }
      }
    }
  }

  .field-terms {

    a,
    .field-terms-underline {
      padding-inline-start: 0.5em;
      color: inherit;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .react-select-container {
    height: 2.5em;
    width: 23.5em;
    font-size: .875em;
  }

  .react-select-control {
    min-height: 0;
    border: 0.03125em solid #353d2f;
    padding-inline-start: 1em;
    border-radius: 1px;
  }

  .react-select-value-container,
  .react-select-input {
    padding: 0;
    margin: 0;
  }

  .field-cb-wrapper {
    margin-bottom: 1.3125em;

    label {
      display: flex;
      align-items: center;
      font-size: 0.75em;

    }

    input {
      width: 1.08em;
      height: 1.08em;
      font-size: inherit;
      margin-left: 0.75em;
    }

    input {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: 1px solid #353D2F;
      outline: none;
    }

    input:checked {
      background-color: #353D2F;
      border: 1px solid #353D2F;
    }

    input:focus {
      outline: none;
    }
  }

  .error-wrapper {
    position: absolute;
    font-size: 0.975em;
    color: #CF582C;
  }

  .error-submit-wrapper {
    transform: translate(-50%, 0);
    left: 50%;
  }

  .contact-btn {
    margin-top: 3.1875em;
    font-size: 1em;
    background-color: transparent;
    border: 1px solid #353D2F;
    border-radius: 3.33em;
    color: inherit;
    height: 2.166em;
    width: 10.916em;
    display: block;
    font-family: inherit;
    margin-inline: auto;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;
    margin-left: auto;
    margin-right: auto;
  }

  .contact-btn:hover {
    background-color: #353D2F;
    color: #FFFEF8;
  }
}

.contact-process {
  margin-inline: auto;
  margin-top: 3.8125em;
  margin-left: auto;
  margin-right: auto;

  .contact-process-title {
    font-size: 1.062em;
    margin-bottom: 4.235em;
  }

  .contact-process-error {
    text-align: center;
  }

  .contact-process-err-btn {
    cursor: pointer;
    margin-top: calc(100vh * 30 / 100);
    font-size: 0.75em;
    background-color: transparent;
    border: 1px solid #353D2F;
    border-radius: 3.33em;
    color: inherit;
    height: 2.166em;
    width: 10.916em;
    display: block;
    font-family: inherit;
    margin-inline: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .app-loader {
    margin-inline: auto;
    width: 2em;
    height: 2em;
  }
}

