.payment {
  background-color: rgba(237, 236, 193, 0.18);
  color: #353D2F;
  direction: rtl;
  align-items: center;

  .CG {
    width: 35%;
    min-width: 20em;
    text-align: center;
    padding: 1vw;
    iframe {
      height: 20em;
      border: none;
      width: 100%;
      margin: 0 auto;
      min-width: 20em;
      margin-top: 0;
    }
    &.error {
      background-color: #f3bbb9;
    }
    .error-text {
      margin: 0;
      text-align: start;
    }
  }

  .payment-title {
    margin-top: 3.8125em;
    text-align: center;
    font-size: 1.062em;
    margin-bottom: 4.235em;
  }

  .payment-title-name {
    font-size: 1.4em;
  }
}
