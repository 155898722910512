.success {
  background-color: #353D2F;
  direction: rtl;

  .success-title {
    font-size: 1.0625em;
    color: #EDECC1;
    text-align: center;
    margin-top: 3.58em;
  }

  .success-title-smile {
    direction: ltr;

    span {
      font-size: 1.5em;
    }

  }

  .success-btn {
    cursor: pointer;
    margin-top: calc(100vh * 30 / 100);
    font-size: 0.75em;
    background-color: #EDECC1;
    border: 1px solid #353D2F;
    border-radius: 3.33em;
    color: inherit;
    height: 2.166em;
    width: 10.916em;
    display: block;
    font-family: inherit;
    margin-inline: auto;
    //for safari
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
