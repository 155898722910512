.modal-content {
  position: relative;
  outline: none;
  inset: 0;
  background-color: white;
}

.modal-header {
  position: sticky;
  top: 0;
  right: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 1em;
}

.modal-btn-close {
  font-size: 2em;
  background: transparent;
  border: transparent;
  color: #776969;
}

.modal-body {
  max-height: 100vh;
  /* Adjust as needed */
  overflow-y: auto;
}
