.app-logo {
  width: 7.6875em;
  margin-inline: auto;
  display: block;
  margin-top: 4.4375em;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: 767px) {
    width: 9.6875em;
    margin-top: 7.125em;
  }
}
